<template>
    <section class="component-box">
        <div class="flex items-center justify-between space-x-2 mb-4">
            <div>
                <p class="font-semibold">
                    {{ $t('common.tiktok_accounts') }}
                </p>
            </div>

            <div>
                <el-button
                    :disabled="!current_package"
                    type="primary"
                    size="small"
                    icon="el-icon-user"
                    @click="show_dialog.add_tiktok_account = true"
                >
                    {{ $t('page.tiktok_identity.add_tiktok_account') }}
                </el-button>
            </div>
        </div>

        <el-alert
            :title="$t('page.tiktok_identity.info')"
            type="success"
            class="mb-2"
            :closable="false"
        >
        </el-alert>

        <!--        <pre>{{ request_list }}</pre>-->
        <el-table v-loading="m__loading" :data="request_list">
            <el-table-column label="#" type="index" width="60" />

            <el-table-column
                width="240"
                :label="$t('page.tiktok_identity.tiktok_id')"
            >
                <template slot-scope="scope">
                    <a
                        :href="`https://www.tiktok.com/@${scope.row.tiktok_username}`"
                        target="_blank"
                    >
                        <span class="underline"
                            >@{{ scope.row.tiktok_username }}</span
                        >
                    </a>
                </template>
            </el-table-column>
            <el-table-column width="240" :label="$t('common.name')">
                <template slot-scope="scope">
                    {{ scope.row.tiktok_nickname }}
                </template>
            </el-table-column>
            <el-table-column width="180" :label="$t('common.status')">
                <template slot-scope="scope">
                    {{ $t(`page.shop.${scope.row.status.toLowerCase()}`) }}
                </template>
            </el-table-column>
            <el-table-column width="120" :label="$t('common.created_at')">
                <template slot-scope="scope">
                    {{
                        scope.row.created_at
                            | f__format_moment($t('common.format_date'))
                    }}
                </template>
            </el-table-column>
            <el-table-column min-width="240" :label="$t('button.confirm')">
                <template slot-scope="scope">
                    <template v-if="scope.row.status !== 'SUCCESS'">
                        <el-button
                            v-if="scope.row.scanned"
                            type="text"
                            size="small"
                        >
                            <strong class="text-primary">{{
                                $t('common.request_sent')
                            }}</strong>
                        </el-button>
                        <el-button
                            v-else
                            type="success"
                            size="small"
                            @click="commitRequestAnIdentity(scope.row)"
                        >
                            {{ $t('common.confirm_linked') }}
                        </el-button>
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                width="300"
                :label="$t('common.option')"
            >
                <template slot-scope="scope">
                    <template v-if="scope.row.status !== 'SUCCESS'">
                        <el-button
                            type="primary"
                            size="small"
                            @click="show_dialog.get_qr_code = true"
                        >
                            {{ $t('page.tiktok_identity.get_qr_code') }}
                        </el-button>
                    </template>
                    <el-button
                        type="danger"
                        size="small"
                        @click="
                            deleteRequestIdentity(scope.row.tiktok_username)
                        "
                    >
                        {{ $t('button.delete') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center mt-4 justify-end">
            <el-switch
                v-model="switch_auto_refresh"
                @change="handleToggleAutoRefresh"
            ></el-switch>
            <p class="text-xs mx-1">
                {{ $t('page.campaign.auto_refresh') }}
            </p>
            <el-tooltip
                effect="dark"
                :content="$t('page.campaign.desc_auto_refresh')"
                placement="top"
            >
                <i class="el-icon-info text-desc-text"></i>
            </el-tooltip>
        </div>

        <el-dialog
            v-loading="loading.add_tiktok_account"
            :visible.sync="show_dialog.add_tiktok_account"
            :append-to-body="true"
            :close-on-click-modal="true"
            width="520px"
        >
            <div slot="title">
                <div class="text-left truncate w-4/5 text-base font-semibold">
                    {{ $t('page.tiktok_identity.dialog_title') }}
                </div>
            </div>

            <div>
                <div>
                    <el-alert
                        :title="$t('page.tiktok_identity.how_find_it_message')"
                        type="success"
                        :closable="false"
                        class="mb-2"
                    >
                    </el-alert>

                    <el-input
                        v-model="form_add_tiktok_account.tiktok_username"
                        size="small"
                        :placeholder="
                            $t('page.tiktok_identity.enter_tiktok_account_id')
                        "
                    ></el-input>
                </div>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4 justify-between">
                    <div>
                        <!--                        <el-button-->
                        <!--                            type="primary"-->
                        <!--                            size="small"-->
                        <!--                            @click="show_dialog.get_qr_code = true"-->
                        <!--                        >-->
                        <!--                            {{ $t('page.tiktok_identity.get_qr_code') }}-->
                        <!--                        </el-button>-->
                    </div>
                    <div>
                        <el-button
                            size="small"
                            @click="show_dialog.add_tiktok_account = false"
                        >
                            {{ $t('button.close') }}
                        </el-button>

                        <el-button
                            type="primary"
                            size="small"
                            icon="el-icon-check"
                            @click="requestAnIdentity"
                        >
                            {{ $t('button.confirm') }}
                        </el-button>
                    </div>
                </div>
            </template>
        </el-dialog>

        <el-dialog
            v-loading="loading.get_qr_code"
            :visible.sync="show_dialog.get_qr_code"
            :append-to-body="true"
            :close-on-click-modal="true"
            width="600px"
        >
            <div slot="title">
                <div class="text-left truncate w-4/5 text-base font-semibold">
                    {{ $t('page.tiktok_identity.dialog_title') }}
                </div>
            </div>

            <div>
                <div style="word-break: break-word">
                    {{ $t('page.tiktok_identity.dialog_note') }}
                </div>

                <div class="mt-6">
                    <el-button
                        size="mini"
                        icon="el-icon-copy-document"
                        @click="copyQRCode"
                    >
                        {{ $t('page.tiktok_identity.copy_qr_code') }}
                    </el-button>
                </div>

                <div class="mt-3">
                    <div
                        class="rounded-[8px] py-[22px] px-[32px]"
                        :style="{
                            background:
                                'linear-gradient(35.36deg,#121212 22.27%,#444 99.35%)'
                        }"
                    >
                        <div class="flex">
                            <div>
                                <img :src="getQrCodeURL()" alt="" />
                            </div>
                            <div class="pl-[30px] text-[#fff]">
                                {{
                                    $t('page.tiktok_identity.dialog_content_1')
                                }}
                                <br />
                                <br />
                                {{
                                    $t('page.tiktok_identity.dialog_content_2')
                                }}
                                <br />
                                {{
                                    $t('page.tiktok_identity.dialog_content_3')
                                }}
                                <br />
                                <br />
                                {{
                                    $t('page.tiktok_identity.dialog_content_4')
                                }}
                                <br />
                                {{
                                    $t('page.tiktok_identity.dialog_content_5')
                                }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-6 bg-[f8f8f8] text-[#000] pl-[30px]">
                    <strong>{{ $t('page.tiktok_identity.scan_code') }}</strong>
                    <br />
                    {{ $t('page.tiktok_identity.scan_code_1') }}
                    <br />
                    {{ $t('page.tiktok_identity.scan_code_2') }}
                    <br />
                    {{ $t('page.tiktok_identity.scan_code_3') }}
                    <br />
                    {{ $t('page.tiktok_identity.scan_code_4') }}
                </div>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4">
                    <el-button
                        type="primary"
                        size="small"
                        @click="show_dialog.get_qr_code = false"
                    >
                        {{ $t('common.i_got_it') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </section>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import {
    commitRequestAnIdentity,
    deleteRequestIdentity,
    getRequestIdentity,
    requestAnIdentity
} from '@/services/atosa-tiktok-ads/identity'

export default {
    mixins: [selectedTiktokBusinessMixin],

    data() {
        return {
            polling: null,
            switch_auto_refresh: true,
            show_dialog: {
                add_tiktok_account: false,
                get_qr_code: false
            },
            loading: {
                add_tiktok_account: false,
                get_qr_code: false
            },
            form_add_tiktok_account: {
                tiktok_username: ''
            },
            request_list: []
        }
    },

    computed: {
        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        }
    },

    watch: {
        m__selected_tiktok_business: function () {
            this.getRequestIdentity()
        }
    },

    mounted() {
        this.getRequestIdentity()

        this.handleToggleAutoRefresh()
    },

    beforeDestroy() {
        clearInterval(this.polling)
    },

    methods: {
        handleToggleAutoRefresh() {
            if (this.switch_auto_refresh) {
                this.polling = setInterval(() => {
                    this.getRequestIdentity()
                }, 10e3)
            } else {
                clearInterval(this.polling)
            }
        },

        async copyQRCode() {
            await this.p__CopyText(this.getQrCodeURL())
        },

        getQrCodeURL() {
            return `${process.env.VUE_APP_ATOSA_TIKTOK_ADS_API.replace(
                '/api/v1',
                ''
            )}/qrcode/qrcode.png`
        },

        async getRequestIdentity() {
            if (!this.m__selected_tiktok_business) {
                return
            }

            this.m__loading = true

            try {
                const response = await getRequestIdentity(
                    this.m__selected_tiktok_business.id
                )
                this.request_list = response.data
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async deleteRequestIdentity(tiktok_username) {
            if (!this.m__selected_tiktok_business) {
                return
            }

            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteRequestIdentity(
                    this.m__selected_tiktok_business.id,
                    tiktok_username
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        },

        async requestAnIdentity() {
            if (
                !this.m__selected_tiktok_business ||
                !this.form_add_tiktok_account.tiktok_username
            ) {
                return
            }

            this.loading.add_tiktok_account = true
            try {
                await requestAnIdentity(
                    this.m__selected_tiktok_business.id,
                    this.form_add_tiktok_account
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.request_sent_success')
                )
                this.show_dialog.add_tiktok_account = false
                this.show_dialog.get_qr_code = true
            } catch (e) {
                console.error(e)
            } finally {
                this.loading.add_tiktok_account = false
            }
        },

        async commitRequestAnIdentity(data) {
            if (!this.m__selected_tiktok_business) {
                return
            }

            try {
                await this.$confirm(
                    this.$t('page.tiktok_identity.confirm_linked_message'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await commitRequestAnIdentity(
                    this.m__selected_tiktok_business.id,
                    data.tiktok_username
                )
                await this.getRequestIdentity()
                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        }
    }
}
</script>
